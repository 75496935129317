
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    


    .c-slide-header {
        position: fixed;
        top: 0;
        height: 48px;
        width: 100%;
        background-color: $nu-white;

        @include breakpoint(medium) {
            height: 64px;
            padding: 0 $nu-spacer-1 0;
        }

        &,
        &__container,
        &__close-link {
            display: flex;
            align-items: center;
        }

        &__container {
            $this-container: &;

            justify-content: center;

            &--left {
                flex: 0 0 80px;

                @include breakpoint(medium) {
                    justify-content: flex-start;
                }

                &#{$this-container}--no-progressbar {
                    @include breakpoint(small) {
                        justify-content: center;
                        flex: 1 1 auto;
                    }
                }
            }

            &--center {
                flex: 0 1 1440px;
                padding: 0 $nu-spacer-1;

                @include breakpoint(small) {
                    padding: 0 $nu-spacer-6;
                }

                @include breakpoint(medium) {
                    padding: 0 $nu-spacer-1;
                }

                @include breakpoint(large) {
                    padding: 0 $nu-spacer-3;
                }

                &#{$this-container}--no-progressbar {
                    padding: 0;

                    @include breakpoint(small) {
                        order: -1;
                    }
                }
            }

            &--right {
                flex: 0 0 auto;

                @include breakpoint(medium) {
                    justify-content: flex-end;
                }
            }

            &--center,
            &--right {
                &#{$this-container}--no-progressbar {
                    @include breakpoint(small) {
                        flex: 0 0 56px;
                    }
                }
            }

            &--left,
            &--right {
                @include breakpoint(medium) {
                    flex: 1 0 auto;
                }
            }

            &--full-width {
                flex-grow: 1;
            }
        }

        &__logo {
            fill: $nu-primary;
            width: 56px;
            margin: 0 $nu-spacer-1 0 $nu-spacer-2;

            @include breakpoint(medium) {
                width: 64px;
                margin: 0 $nu-spacer-3;
            }
        }

        &__close-button,
        &__close-link {
            height: 48px;
            width: 48px;
            flex: 0 0 48px;
            justify-content: center;
            cursor: pointer;
        }
    }
