
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    


    .c-slide-header {
        position: fixed;
        top: 0;
        height: 48px;
        width: 100%;
        background-color: $nu-white;
        justify-content: center;

        @include breakpoint(medium) {
            height: 64px;
            padding: 0 $nu-spacer-1 0;
        }

        &,
        &__container,
        &__link {
            display: flex;
            align-items: center;
        }

        &__container {
            $this-container: &;

            &--left {
                justify-content: flex-start;
            }

            &--center {
                padding: 0 $nu-spacer-1;
                justify-content: center;
            }

            &--right {
                justify-content: flex-end;
            }

            &--left,
            &--right {
                flex: 1 1 80px;
            }

            &--full-width {
                flex-grow: 1;
            }
        }

        &__logo {
            fill: $nu-primary;
            width: 56px;
            margin: 0 $nu-spacer-1 0 $nu-spacer-2;

            @include breakpoint(medium) {
                width: 64px;
                margin: 0 $nu-spacer-3;
            }
        }

        &__close-button,
        &__link {
            height: 48px;
            width: 48px;
            flex: 0 0 48px;
            justify-content: center;
            cursor: pointer;
        }
    }
