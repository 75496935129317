
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-async-content-fadein {
        height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: 750ms ease;

        &--is-mounted {
            opacity: 1;
            overflow: unset;
        }
    }
