$nu-spacers: (
    0pt5: 4px,
    1:    8px,
    1pt5: 12px,
    2:    16px,
    2pt5: 20px,
    3:    24px,
    3pt5: 28px,
    4:    32px,
    4pt5: 36px,
    5:    40px,
    6:    48px,
    6pt5: 52px,
    7:    56px,
    8:    64px,
    9:    72px,
    10:   80px,
    12:   96px,
    13:   104px,
    14:   112px,
    15:   120px,
    16:   128px,
    20:   160px,
);

$nu-spacer-0pt5: map-get($nu-spacers, 0pt5);
$nu-spacer-1:    map-get($nu-spacers, 1);
$nu-spacer-1pt5: map-get($nu-spacers, 1pt5);
$nu-spacer-2:    map-get($nu-spacers, 2);
$nu-spacer-2pt5: map-get($nu-spacers, 2pt5);
$nu-spacer-3:    map-get($nu-spacers, 3);
$nu-spacer-3pt5: map-get($nu-spacers, 3pt5);
$nu-spacer-4:    map-get($nu-spacers, 4);
$nu-spacer-4pt5: map-get($nu-spacers, 4pt5);
$nu-spacer-5:    map-get($nu-spacers, 5);
$nu-spacer-6:    map-get($nu-spacers, 6);
$nu-spacer-7:    map-get($nu-spacers, 7);
$nu-spacer-8:    map-get($nu-spacers, 8);
$nu-spacer-9:    map-get($nu-spacers, 9);
$nu-spacer-10:    map-get($nu-spacers, 10);
$nu-spacer-12:    map-get($nu-spacers, 12);
$nu-spacer-13:    map-get($nu-spacers, 13);
$nu-spacer-14:    map-get($nu-spacers, 14);
$nu-spacer-15:    map-get($nu-spacers, 15);
$nu-spacer-16:    map-get($nu-spacers, 16);
$nu-spacer-20:    map-get($nu-spacers, 20);
