// Header styles

@mixin heading-1 {
    font-family: $typeface-ginto;
    font-size: 2.6rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.3px;
    line-height: 28px;
}

@mixin heading-2 {
    font-family: $typeface-americana;
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.6px;
    line-height: 32px;
}

@mixin heading-3 {
    font-family: $typeface-ginto;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.3px;
    line-height: 20px;
}

@mixin heading-4 {
    font-family: $typeface-americana;
    font-size: 1.8rem; // ask brandon about size here
    font-weight: $font-weight-bold;
    letter-spacing: 0.6px;
    line-height: 24px;
}

@mixin heading-5 {
    font-family: $typeface-sharp;
    font-size: 1.4rem;
    font-weight: $font-weight-medium;
    letter-spacing: 0.4px;
    line-height: 24px;
}

@mixin heading-6 {
    font-family: $typeface-americana;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.6px;
    line-height: 16px;
}


@mixin title-1 {
    font-family: $typeface-ginto;
    font-size: 4.8rem;
    letter-spacing: 0.3px;
    line-height: 56px;
}

@mixin title-2 {
    font-family: $typeface-americana;
    font-size: 4.8rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.4px;
    line-height: 56px;
}

@mixin title-3 {
    font-family: $typeface-ginto;
    font-size: 3.2rem;
    letter-spacing: 0.3px;
    line-height: 36px;
}

@mixin title-4 {
    font-family: $typeface-americana;
    font-size: 3.2rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.4px;
    line-height: 40px;
}


// Font styling classes

@mixin body-copy {
    font-family: $typeface-sharp;
    font-size: 1.4rem;
    font-weight: $font-weight-normal;
    letter-spacing: 0.4px;
    line-height: 24px;
    text-transform: none;
}

@mixin error-text {
    color: $nu-primary;
}

@mixin error-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $nu-spacer-0pt5;
    margin-top: -$nu-spacer-0pt5;
    line-height: 16px;
}

@mixin label {
    font-family: $typeface-sharp;
    font-size: 1rem;
    font-weight: $font-weight-medium;
    letter-spacing: 1.2px;
    line-height: 12px;
    text-transform: uppercase;
}

@mixin caption {
    font-family: $typeface-sharp;
    font-size: 1.2rem;
    font-weight: $font-weight-normal;
    letter-spacing: 0.3px;
    line-height: 20px;
}

$focus-outline: 4px solid rgba($nu-primary, 0.4);

.js-focus-visible {
    :focus[data-focus-visible-added] {
        outline: $focus-outline;
    }

    :focus:not([data-focus-visible-added]),
    input:focus[data-focus-visible-added],
    textarea:focus[data-focus-visible-added] {
        outline: none;
    }
}


// Other text mixins

// Make the text contents of a container trail off in an ellipsis instead of overflowing
@mixin ellipsize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// fix block element flow that's been disrupted by a floated element
// https://www.w3schools.com/howto/howto_css_clearfix.asp
@mixin clearfix {
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}
