$breakpoints: (
    extra-small: 0px,
    small: 568px,
    medium: 768px,
    large: 1025px,
    extra-large: 1441px
);

@mixin breakpoint($name) {
    @if not map-has-key($breakpoints, $name) {
        @warn "Warning: `#{$name}` is not a valid breakpoint name.";
    } @else {
        @media (min-width: map-get($breakpoints, $name)) {
            @content;
        }
    }
}

// Create a comma-separated string of our 5 numeric breakpoint sizes that can be
// communicated up to JS
$breakpoint-sizes: '#{map-get($breakpoints, 'extra-small')},#{map-get($breakpoints, 'small')},#{map-get($breakpoints, 'medium')},#{map-get($breakpoints, 'large')},#{map-get($breakpoints, 'extra-large')}';

@mixin js-breakpoint-support() {

    body {
        &:after {
            content: 'extra-small,#{$breakpoint-sizes}';
            display: none;

            // For debugging, you can uncomment this section to get a small
            // active breakpoint displayed in the bottom right hand corner
            // display: block;
            // position: absolute;
            // bottom: 0;
            // right: 0;
            // background: rgba(100,100,100,0.5);

            @include breakpoint(small) {
                content: 'small,#{$breakpoint-sizes}';
            }

            @include breakpoint(medium) {
                content: 'medium,#{$breakpoint-sizes}';
            }

            @include breakpoint(large) {
                content: 'large,#{$breakpoint-sizes}';
            }

            @include breakpoint(extra-large) {
                content: 'extra-large,#{$breakpoint-sizes}';
            }
        }
    }
}

@mixin non-touch-device {
    @media (hover: hover) and (pointer: fine) {
        @content;
    }
}

@mixin touch-device {
    @media (pointer: coarse) {
        @content;
    }
}
