// *****************
// *** Primaries ***
// *****************

$nu-olive:      #505A46;
$nu-crimson:    #823232;
$nu-poppy:      #A04100;
$nu-navy:       #365261;
$nu-ochre:      #785028;


// *******************
// *** Secondaries ***
// *******************/
// Each secondary color has 5 tints, where 100 is the darkest and 20 is the lightest

// Blush
$nu-blush-100:  #E6D7C8;
$nu-blush-80:   #EBDFD3;
$nu-blush-60:   #F0E7DE;
$nu-blush-40:   #F5EFE9;
$nu-blush-20:   #FAF7F4;

// Sunshine
$nu-sunshine-100:   #E6D273;
$nu-sunshine-80:    #EBDF9B;
$nu-sunshine-60:    #F0E7B4;
$nu-sunshine-40:    #F5EFCD;
$nu-sunshine-20:    #FAF7E6;

// Swimming Pool
$nu-swimming-pool-100:  #D2DCE1;
$nu-swimming-pool-80:   #DBE3E7;
$nu-swimming-pool-60:   #E4EAED;
$nu-swimming-pool-40:   #EDF1F3;
$nu-swimming-pool-20:   #F6F8F9;

// Lilac
$nu-lilac-100:  #E1CDDC;
$nu-lilac-80:   #E7D7E3;
$nu-lilac-60:   #EDE1EA;
$nu-lilac-40:   #F3EBF1;
$nu-lilac-20:   #F9F5F8;

// Melon
$nu-melon-100:  #F5C8AA;
$nu-melon-80:   #F7D3BB;
$nu-melon-60:   #F9DECC;
$nu-melon-40:   #FBE9DD;
$nu-melon-20:   #FDF2EC;

// Honeydew
$nu-honeydew-100:  #D4E4BF;
$nu-honeydew-80:   #DDE9CC;
$nu-honeydew-60:   #E5EFD9;
$nu-honeydew-40:   #EEF4E5;
$nu-honeydew-20:   #F6FAF2;


// *******************
// *** Filters ***
// *******************/
// Each filter only works when applied to transparent PNGs with black content

// primary colors
$nu-filter-color--nu-poppy: brightness(0) saturate(100%) invert(25%) sepia(99%) saturate(1062%)
    hue-rotate(2deg) brightness(97%) contrast(104%);
$nu-filter-color--nu-olive: brightness(0) saturate(100%) invert(35%) sepia(0%) saturate(4505%)
    hue-rotate(245deg) brightness(90%) contrast(98%);
$nu-filter-color--nu-crimson: brightness(0) saturate(100%) invert(24%) sepia(12%) saturate(3144%)
    hue-rotate(312deg) brightness(100%) contrast(94%);
$nu-filter-color--nu-navy: brightness(0) saturate(100%) invert(27%) sepia(38%) saturate(374%)
    hue-rotate(153deg) brightness(102%) contrast(95%);
$nu-filter-color--nu-ochre: brightness(0) saturate(100%) invert(33%) sepia(7%) saturate(3392%)
    hue-rotate(349deg) brightness(98%) contrast(94%);


// ***************
// *** Globals ***
// ***************/
$nu-primary:              $nu-navy;
$nu-secondary:            $nu-blush-20;
$nu-secondary--100:       $nu-blush-100;
$placeholder-background:  $nu-blush-40;
$nu-secondary-60:         $nu-blush-60;
$nu-secondary-40:         $nu-blush-40;
$nu-secondary-20:         $nu-blush-20;
$nu-filter-color:         $nu-filter-color--nu-navy;

// Grayscale
$nu-black:         #2C2C2C;
$nu-gray:          #6C6C6C;
$nu-gray--medium:  #D7D7D7;
$nu-gray--light:   #F7F7F7;
$nu-white:         #FFFFFF;


$transparent: rgba(0,0,0,0);
$modal-overlay: rgba($nu-gray, 0.2);

$nu-focus-primary: mix($nu-primary, $nu-secondary, 40%);

// sass variable exports for JS can be found in _colors-js.icss.scss
