
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-slide-layout {
        $this: &;

        // create new stacking context to avoid z-index issues
        position: relative;

        &__header {
            z-index: 1;
        }

        &__main-content {

            // TODO: this is to offset the header height, consider using a var to set this
            padding-top: $nu-spacer-6;
            z-index: 0;

            @include breakpoint(medium) {
                padding-top: $nu-spacer-8;
            }

            &--centered {
                overflow: scroll;
                min-height: 100vh;

                #{$this}__inner {
                    padding: $nu-spacer-8 0;
                    flex: 1;

                    @include breakpoint(medium) {
                        padding: $nu-spacer-12 0;
                    }
                }
            }

            &--mobile-webview {
                padding-top: 0;
            }
        }

        &__inner {
            flex: 1;
        }
    }
